<template>
     <div class="page-content">
              
             
            </div>




    
</template>
<script>

    import clickMixin from '@/Mixins/clickMixin'

    export default {


        mixins: [clickMixin],

        data: function () {

            return {
               
            }
        }
        
    }
</script>