export default {

    methods: {
        isProduction: function (x) {
            var isProduction = JSON.parse(localStorage.getItem("IsProduction"));
            if (x === isProduction) {
                return true;
            }
        },
        checkArabicNumberMixinFunction: function (modifiedValue) {
            
            if(/^[0-9\u0660-\u0669]+$/.test(modifiedValue)==true)
                {
                    modifiedValue = modifiedValue.replace(/[٠-٩]/g, d => "٠١٢٣٤٥٦٧٨٩".indexOf(d)).replace(/[۰-۹]/g, d => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
                 return modifiedValue;
                }
                else
                {
                    return modifiedValue;
                }
        },
        isMonthPicker: function () {
            if (this.$i18n.locale == 'en') 
                return 'en'
            
            else if (this.$i18n.locale == 'ar')
                return 'ar'
            else if (this.$i18n.locale == 'pt')
                return 'pt'
            else
                return 'en'

        },
        isValid: function () {
            return true;
           
            // var storedColors = JSON.parse(localStorage.getItem("permission"));
            // if (x === 'Noble Admin') {
            //     return true;
            // }
            // else if (storedColors != undefined) {


            //     var isFound = storedColors.find(function (item) {
            //         if (item.permissionCategory === x) {
            //             return true;

            //         }
            //         return false;
            //     });
            //     if (isFound !== undefined) {
            //         return true;
            //     }
            //     else {
            //         return false;
            //     }
            // }

        },
        
        isLeftToRight: function () {
           
            return localStorage.getItem('LeftToRight') == "true" ? true : false;


        },
       
        isOtherLang: function () {
            var getArabicLanguage = localStorage.getItem('Arabic');
            var getPortuguesLanguage = localStorage.getItem('Portugues');
            if (getArabicLanguage == 'true' || getPortuguesLanguage == 'true')
                return true
            return false



        },
        languageForCSV: function () {
            var english = true 
            var arabic = localStorage.getItem('Arabic') == 'true' ? true : false;
            if (english && arabic) {
                return 'ar';
            }
            if (arabic) {
                return 'ar';
            }
            else {
                return 'en'
            }
        },

        havePermission:function(x){
            let permissionString = localStorage.getItem('Permissions');    
            let result = false;                
            permissionString.split(',').map(item => {
                if(item.trim() == x)
                    result =  true;
            });
            return result;
        }
        
        
      
    }
 
}

