import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store= new Vuex.Store({
    state: {
      
        sizeList: [],
        currencyList: [],
        taxRateList: [],
        unitList: [],
        accounts:[]
      
  },
    mutations: {
        setAuthentication(state, status) {
            state.authenticated = status;
        }
  },
    actions: {
        
       
        
        GetAccountList: function (commit, accounts) {
            
            store.state.accounts = accounts;
        },
       
        GetSizeList: function (commit, sizeList) {
            
            
            store.state.sizeList = sizeList;
        },
        GetTaxRateList: function (commit, taxRateList) {
            
            
            store.state.taxRateList = taxRateList;
        },
        GetCurrencyList: function (commit, currencyList) {
            
            
            store.state.currencyList = currencyList;
        },
        GetUnitList: function (commit, unitList) {
            
            
            store.state.unitList = unitList;
        },
        
       
        
       
        
  },
  modules: {
  }
})
