<template>
    <div class="row" v-if="isValid('CanUpdateCompanyInfo')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">Company Permission</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                    <li class="breadcrumb-item active">Company Permission</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">                  
                    <form  class="form-horizontal form-wizard-wrapper wizard clearfix" role="application">
                        <div class="content clearfix">
                            <fieldset id="form-horizontal-p-0" aria-labelledby="form-horizontal-h-0" class="current" aria-hidden="false">
                                
                                <div class="row">
                                    <div class="col-lg-12 card-header mb-4">
                                        <h4 class="page-title">Permission</h4>
                                    </div>
                                </div><!--end row-->
                                  <div class="row">
                                    <div class="col-4" v-for="(permission) in permissionOption" v-bind:key=" permission.name">
                                                    <div class="checkbox form-check-inline mx-2 "  >
                                                            <input type="checkbox" :id="permission.name" v-model="permission.checked">
                                                            <label :for="permission.name">{{ permission.name }}</label>
                                                        </div>
                                                </div>
                                   
                                </div><!--end row-->
                                
                              
                            </fieldset><!--end fieldset-->
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class=" col-md-12">
                            <div class="button-items">
                                <button class="btn btn-outline-primary   mr-2" v-on:click="SaveCompanyAccountSetup()"> <i class="far fa-save "></i>  {{ $t('CompanyProfile.Update') }}</button>
                                <button class="btn  btn-danger   mr-2" v-on:click="BackToList()">{{ $t('CompanyProfile.btnClear') }}</button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default ({
        components: {

        },
        mixins: [clickMixin],
        data: function () {
            return {
                permissionOption:[{
                    name:'Sale',
                    checked:false
                },
                {
                    name:'Purchase',
                    checked:false
                },
                {
                    name:'Reporting',
                    checked:false
                },
                {
                    name:'Product',
                    checked:false
                },
                {
                    name:'Service',
                    checked:false
                },
               
                {
                    name:'MultiUnit',
                    checked:false
                },
                {
                    name:'Discount',
                    checked:false
                },
                {
                    name:'Product with Quantity',
                    checked:false
                },
                {
                    name:'Service with Quantity',
                    checked:false
                },
                {
                    name:'Contact Attachment',
                    checked:false
                },
                {
                    name:'Contact Email',
                    checked:false
                },
                {
                    name:'Contact Contract',
                    checked:false
                },
                {
                    name:'CaseInvoice',
                    checked:false
                }],
                companyProfile: {
                    id: '00000000-0000-0000-0000-000000000000',
                    unitId: '00000000-0000-0000-0000-000000000000',
                    nameInArabic: '',
                    nameInEnglish: '',
                    categoryInEnglish: '',
                    categoryInArabic: '',
                    CompanyRegNo: '',
                    vatRegistrationNo: '',
                    companyNameArabic: '',
                    companyNameEnglish: '',
                    addressInEnglish: '',
                    addressInArabic: '',
                    logoPath: '',
                    countryInArabic: '',
                    countryInEnglish: '',
                    cityInEnglish: '',
                    cityInArabic: '',
                    landLine: '',
                    companyEmail: '',
                    productPrice: '',
                    phoneNo: '',
                    attachmentList: [],
                    permissions:[]
                },
                companyId: '',
                image: '',
                renderedImage: 0,
                isDisable: false,
                isDelete: false,
                show: false,
                arabic: '',
                english: ''
            }
        },
       
        
        methods: {
            permissionList:function(x){
                this.companyProfile.permissions.push(x);
                console.log(this.companyProfile.permissions)
            },
            BackToList: function () {
                this.$router.push('/StartScreen')


            },
            SaveCompanyAccountSetup: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.companyProfile.permissions = [];
                this.permissionOption.forEach(x=>{
                    if(x.checked)
                        this.companyProfile.permissions.push(x.name)
                });
                console.log(this.companyProfile.permissions);
                this.$https.post('/Company/SaveLocation', this.companyProfile, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.$swal({
                            title: root.$t('CompanyInfo.Saved'),
                            text: root.$t('CompanyInfo.SavedSuccessfully'),
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        });
                        var permissionStr = root.companyProfile.permissions.join(',');
                        localStorage.setItem('Permissions',permissionStr)
                        root.$router.push('/StartScreen');

                    }
                });
            },
            GetCompanyDetails: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Company/EditCompany?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.companyProfile.id = response.data.id;
                        root.companyProfile.nameInEnglish = response.data.nameEnglish;
                        root.companyProfile.nameInArabic = response.data.nameArabic;

                        root.companyProfile.cityInEnglish = response.data.cityEnglish;
                        root.companyProfile.cityInArabic = response.data.cityArabic;
                        root.companyProfile.addressInEnglish = response.data.addressEnglish;
                        root.companyProfile.addressInArabic = response.data.addressArabic;

                        root.companyProfile.countryInEnglish = response.data.countryEnglish;
                        root.companyProfile.landLine = response.data.landLine;
                        root.companyProfile.countryInArabic = response.data.countryArabic;

                        root.companyProfile.categoryInEnglish = response.data.categoryEnglish;
                        root.companyProfile.categoryInArabic = response.data.categoryArabic;

                        root.companyProfile.companyRegNo = response.data.comercialRegNo;
                        root.companyProfile.vatRegistrationNo = response.data.vatRegistrationNo;

                        root.companyProfile.companyNameEnglish = response.data.companyNameEnglish;
                        root.companyProfile.companyNameArabic = response.data.companyNameArabic;
                        root.companyProfile.logoPath = response.data.logoPath;
                        root.companyProfile.companyEmail = response.data.companyEmail;
                        root.companyProfile.phoneNo = response.data.phoneNo;
                        root.companyProfile.attachmentList = response.data.attachmentList;
                        root.companyProfile.productPrice = response.data.productPrice;
                        root.companyProfile.isProduction = response.data.isProduction;
                        root.companyProfile.permissions = response.data.permissions;
                        root.permissionOption.forEach(x=>{
                            if(root.isValueInArray(x.name))
                                x.checked = true
                        });

                    }
                });
            },
            isValueInArray(value) {
               return this.companyProfile.permissions.indexOf(value) !== -1;
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = 'true';
            this.arabic = localStorage.getItem('Arabic');

            this.isDisable = true;
            this.companyId = localStorage.getItem('CompanyID');
            this.GetCompanyDetails(this.companyId);

        }
    })

</script>