<template>
    <div>
        <div hidden id='customerLedgerPrintReport' class="col-md-7" style="background-color:white;padding-left:40px !important;padding-right:40px !important ">
            <!--HEADER-->
            
            <div >
                <div class="" >
                 <!-- <img src="header.png" style="width: 100%;height:120px"> -->
                 <HeaderComponent></HeaderComponent>
                </div>

                <!--INFORMATION-->
                <div class=" col-md-12" style="height:20mm;background-color:white;border:1px solid #000000;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="row">
                        <div class="col-md-7" style="display:flex;">
                            <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">From Date:</div>
                            <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{fromDate}}</div>
                            <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important"> </div>
                        </div>
                        <div class="col-md-5" style="display:flex;">
                            <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">To Date:</div>
                            <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{toDate}}</span> </div>
                            <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important">
                              
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12" style="background-color:white;">
                    <div class="row p-1" >
                        <table class="table col-md-12 ">
                            <tr class="heading" style="font-size:16px !important;color:black !important">
                                <th class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;">#</th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">  {{ $t('CustomerLedgerReport.Code') }}</th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">
                                    <span >Customer Name</span>
                                    <!-- <span v-else>{{ $t('CustomerLedgerReport.SupplierLedgerReport') }}</span> -->
                                </th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">Email</th>
                                <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;"> Phone No</th>
                                <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;"> {{ $t('CustomerLedgerReport.Amount') }}</th>


                            </tr>
                            <tr style="font-size:13px !important;color:black !important" v-for="(contact, index) in list" v-bind:key="contact.id">
                                <td class="text-left">{{index+1}}</td>
                                <td class="text-center">{{contact.contactCode}}</td>
                                <td class="text-center">
                                    <span v-if="language=='en'">{{contact.contactName==''?contact.contactNameArabic:contact.contactName}}</span>
                                    <span v-else>{{contact.contactNameArabic==''?contact.contactName:contact.contactNameArabic}}</span>
                                </td>
                                <td class="text-center">{{contact.email}}</td>
                                <td class="text-center">{{contact.contactNo1}}</td>
                                <td class="text-right">
                                    {{contact.amount>0?'Dr':'Cr'}} {{nonNegative(contact.amount) }}
                                </td>


                            </tr>

                            <tr style="font-size:15px;font-weight:bold;background-color:azure">
                                <td colspan="3" class="text-center" style="padding-top:60px">{{ $t('CustomerLedgerReport.TotalDebit') }}:  {{(parseFloat(totalDebit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                <td colspan="2" class="text-left" style="padding-top:60px">
                                    {{ $t('CustomerLedgerReport.TotalCredit') }}:{{(parseFloat(totalCredit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                </td>

                                <td colspan="3" class="text-left" style="padding-top:60px">{{ $t('CustomerLedgerReport.Total') }}: {{(parseFloat(totalDebit-totalCredit)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>

                            </tr>

                        </table>

                    </div>

                </div>
            </div>


        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: [ 'formName', 'isPrint', 'fromDate', 'toDate', 'headerFooter', 'contactList'],
        data: function () {
            return {
                list: [],
                invoicePrint: '',
                Print: false,
                openingCash: 0,
                totalBalance: 0,
                render: 0,
                customerNameEn: '',
                customerNameAr: '',
                isHeaderFooter: '',

                address: '',
                phoneNumber: '',
                customerNo: '',
                customerVat: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                arabic: '',
                english: '',
                listItemP1: [],
                listItemP2: [],
                listItemP3: [],
                itemTotal: 0,
                IsPaksitanClient: false,



            }
        },
        computed: {
            totalDebit: function () {
                
                return this.contactList.reduce(function (a, c) {

                    if (c.amount > 0) {
                        return a + Number((c.amount.toFixed(3).slice(0, -1)) || 0)

                    }
                    else {
                        return a + 0;
                    }


                }, 0)
            },

            totalCredit: function () {

                
                return this.contactList.reduce(function (a, c) {

                    if (c.amount <= 0) {
                        return a + Number((c.amount.toFixed(3).slice(0, -1) < 0 ? c.amount.toFixed(3).slice(0, -1) * -1 : c.amount.toFixed(3).slice(0, -1)) || 0)

                    }
                    else {
                        return a + 0;
                    }


                }, 0)
            },

        },

        mounted: function () {
            
            this.language = this.$i18n.locale;
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            this.IsPaksitanClient = localStorage.getItem('IsPaksitanClient') == "true" ? true : false;

            this.list = this.contactList;
            if (this.list.length > 0) {
                var totalItem = this.list;

                this.itemTotal = this.list.length;
                if (totalItem < 22) {
                    for (var i = 0; i < totalItem; i++) {
                        root.listItemP1.push(root.list[i]);
                    }
                }
                else if (totalItem >= 22 && totalItem < 38) {
                    for (var k = 0; k < totalItem; k++) {
                        if (k < 22) {
                            root.listItemP1.push(root.list[k]);
                        }
                        else {
                            root.listItemP2.push(root.list[k]);
                        }
                    }
                }

                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }

        },
        methods: {
            getDate: function (date) {
                if (date == null || date == '')
                    return '';
                else {
                    return moment(date).format('LLL');

                }
            },
            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            printInvoice: function () {

                this.$htmlToPaper('customerLedgerPrintReport');
            }
        }
    }
</script>